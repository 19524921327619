// function to add localized conjunction components of a list

export function getListComponent(index: number, length: number) {
  const { t } = useI18n()

  if (index < (length - 2)) {
    return ', '
  }
  else if (index == (length - 2)) {
    return ` ${t('and')} `
  }
  else {
    return ''
  }
}
